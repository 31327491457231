import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import './style.css';
import lion from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Lion Retro gentleman animals Barkder.png';
import { Link } from 'react-router-dom';

function Work() {
  const images = [
    { id: 1, src: lion, title: 'Retro Gentleman Animals',slug:"Retro-Gentleman-Animals" },
  ];
  return (
    <>
      <Navbar />
      <div className="works-container">
      <h1 className="works-title">My collection</h1>
      <div className="images-container">
        {images.map((image) => (
          <div key={image.id} className="image-item">
            <Link key={image.id} to={`/collection/${image.slug}`}>
            <div className="image-title">{image.title}</div>
            <img src={image.src} alt={image.title} className="image" />
            </Link>
          </div>
        ))}
      </div>
    </div>
      <Footer />
    </>
  );
}

export default Work;
