import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

function ScrollToTopOnMount() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function Navbar() {
  return (
    <div className="navbar">
      <ScrollToTopOnMount />
      <h1 className="title">Barkder Illustrator</h1>
      <div className='Whitebar'></div>
      <div className="subtitles">
        <Link to="/" className="subtitle">Home</Link>
        <Link to="/work" className="subtitle">Work</Link>
        <Link to="/contact" className="subtitle">Contact</Link>
        <a href="https://www.instagram.com/barkderillustrator/" className="subtitle">Instagram</a>
        <Link to="https://displate.com/BarkderIllustrator?art=64013b633fa0d" className="subtitle">Shop</Link>
      </div>
    </div>
  );
}

export default Navbar;
