import React from 'react';
import './style.css';
import Navbar from '../Navbar';
import lion from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Horse Retro gentleman animals Barkder.png';
import Footer from '../Footer';
import { Link } from 'react-router-dom';


function Contact() {
  return (
    <>
      <Navbar />
      <div className='container-contact'>
        <div className='content-contact'>
          <h1>Contact me at <span className='email-contact'>Barkderdesign@gmail.com</span></h1>
          <Link to="https://www.instagram.com/barkderillustrator/" className='link-contact'> <button className='button-contact'><span className='text'>Instagram</span></button></Link>
        </div>
        <img src={lion} alt="" className='img-contact'/>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
