import './style.css';
import Navbar from '../Navbar';
import Footer from '../Footer';

function MentionsLegales() {
    return (
        <>
            <Navbar />
            <div className="mentions-legales-container">
                <div className="mentions-legales-modal">
                    <div className="mentions-legales-content">
                        <h2>CREDITS / MENTIONS LEGALES CREDITS Site Web © BarkderIllustrator MENTIONS LEGALES</h2>
                        <p>
                            <h2>1. PRÉSENTATION DU SITE.</h2>

                            En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site www.BarkderIllustrator.com l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi :
                            Propriétaire: Barkder Illustrator
                            Hébergeur: Hostinger, UAB Jonavos g. 60C, 44192 Kaunas, Lithuania
                            Crédits : © Barkder Illustrator
                        </p>
                        <p>
                            <h2>2. CONDITIONS GÉNÉRALES D’UTILISATION DU SITE ET DES SERVICES PROPOSÉS.</h2>

                            L’utilisation du site www.BarkderIllustrator.com implique l’acceptation pleine et entière des conditions générales d’utilisation ci-après décrites.
                        </p>

                        <p>
                            <h2>3. DESCRIPTION DES SERVICES FOURNIS. </h2>

                            Le site www.BarkderIllustrator.com a pour objet de fournir une information concernant l’ensemble des activités de Barkder Illustrator et s’efforce de fournir sur le site www.BarkderIllustrator.com des informations aussi précises que possible. Toutefois, il ne pourra être tenue responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations. Tous les informations indiquées sur le site www.BarkderIllustrator.com sont données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur le site www.BarkderIllustrator.com ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
                        </p>

                        <p>
                            <h2>4. LIMITATIONS CONTRACTUELLES SUR LES DONNÉES TECHNIQUES.  </h2>

                            Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour
                        </p>
                        <p>
                            <h2>5. PROPRIÉTÉ INTELLECTUELLE ET CONTREFAÇONS.   </h2>

                            Barkder Illustrator est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Barkder Illustrator. Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                        </p>
                        <p>
                            <h2>6. LIMITATIONS DE RESPONSABILITÉ.   </h2>

                            Barkder Illustrator ne pourra être tenue responsable des dommages directs et indirects causés au matériel de l’utilisateur, lors de l’accès au site www.BarkderIllustrator.com, et résultant soit de l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l’apparition d’un bug ou d’une incompatibilité. Barkder Illustrator ne pourra également être tenue responsable des dommages indirects (tels par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du site www.BarkderIllustrator.com.
                        </p>
                        <p>
                            <h2>7. GESTION DES DONNÉES PERSONNELLES.   </h2>

                            En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995. A l'occasion de l'utilisation du site www.BarkderIllustrator.com peuvent êtres recueillies : l'URL des liens par l'intermédiaire desquels l'utilisateur a accédé au site www.BarkderIllustrator.com le fournisseur d'accès de l'utilisateur, l'adresse de protocole Internet (IP) de l'utilisateur. Aucune information personnelle de l'utilisateur du site www.BarkderIllustrator.com n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers.
                        </p>
                        <p>
                            <h2>8. LIENS HYPERTEXTES ET COOKIES.   </h2>

                            Le site www.BarkderIllustrator.com contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de Barkder Illustrator Cependant, Barkder Illustrator n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait. La navigation sur le site
                            www.BarkderIllustrator.com est susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
                        </p>
                        <p>
                            <h2>9. DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION.   </h2>

                            Tout litige en relation avec l’utilisation du site www.BarkderIllustrator.com est soumis au droit français. Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés. Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default MentionsLegales;