import React from 'react';
import './style.css';
import lion from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Lion Retro gentleman animals Barkder.png'
import Rabbit from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Rabbit Retro gentleman animals Barkder.png';
import Horse from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Horse Retro gentleman animals Barkder.png';
import Bird from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Bird Retro gentleman animals Barkder.png';

const Container = () => {
  return (
    <>
    <h1 className="title-container">Mes créations les plus populaires</h1>
    <div className="container-content">
        <img className="photo" src={lion} alt="photo1" />
        <img className="photo" src={Rabbit} alt="photo2" />
        <img className="photo" src={Horse} alt="photo3" />
        <img className="photo" src={Bird} alt="photo4" />
    </div>
    </>
  );
};

export default Container;
