import React, { useState } from 'react';
import Navbar from '../Navbar';
import './style.css';
import data from '/home/nathan/Desktop/Project/barkderillustrator/src/data.js';
import Footer from '../Footer';

const Retro = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleClose = () => {
    setSelectedImage(null);
  };

  const handleSelect = (image) => {
    setSelectedImage(image);
  };

  const handlePrevious = () => {
    const currentIndex = data.findIndex(image => image === selectedImage);
    setSelectedImage(data[(currentIndex - 1 + data.length) % data.length]);
  };

  const handleNext = () => {
    const currentIndex = data.findIndex(image => image === selectedImage);
    setSelectedImage(data[(currentIndex + 1) % data.length]);
  };

  return (
    <>
      <Navbar />
      <div className="container-retro">
        <h1 className="title-retro">Retro Gentleman Animals</h1>
        <div className="images-retro">
          {data.map((datas) => (
            <img
              key={datas.id}
              src={datas.src}
              alt={datas.title}
              className="image-retro"
              onClick={() => handleSelect(datas)}
            />
          ))}
        </div>
      </div>
      {selectedImage && (
        <div className="fullscreen-overlay">
          <div className="fullscreen-inner">
            <img src={selectedImage.src} alt={selectedImage.title} />
            <button onClick={handlePrevious} className="fullscreen-nav-left">
              &#8592;
            </button>
            <button onClick={handleNext} className="fullscreen-nav-right">
              &#8594;
            </button>
            <button onClick={handleClose} className="fullscreen-close">
              &times;
            </button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default Retro;
