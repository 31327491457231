import React from 'react';
import Video from "/home/nathan/Desktop/Project/barkderillustrator/src/img/Smokecolor.mp4";
import './style.css';
import { Link } from 'react-router-dom';

const Body = () => {
  return (
    <div className="body">
      <video className="background-video" autoPlay loop muted>
        <source src={Video} type="video/mp4"/>
      </video>
      <div className="overlay" />
      <div className="container-body">
        {/* <h1 className="title-body">Dive into Enchanting Digital Universes</h1> */}
        <span className="span-body">D</span>
        <span className="span-body">i</span>
        <span className="span-body">v</span>
        <span className="span-body">e</span>
        <span className="span-body">&nbsp;</span>
        <span className="span-body">i</span>
        <span className="span-body">n</span>
        <span className="span-body">t</span>
        <span className="span-body">o</span>
        <span className="span-body">&nbsp;</span>
        <span className="span-body">E</span>
        <span className="span-body">n</span>
        <span className="span-body">c</span>
        <span className="span-body">h</span>
        <span className="span-body">a</span>
        <span className="span-body">n</span>
        <span className="span-body">t</span>
        <span className="span-body">i</span>
        <span className="span-body">n</span>
        <span className="span-body">g</span>
        <span className="span-body">&nbsp;</span>
        <span className="span-body">D</span>
        <span className="span-body">i</span>
        <span className="span-body">g</span>
        <span className="span-body">i</span>
        <span className="span-body">t</span>
        <span className="span-body">a</span>
        <span className="span-body">l</span>
        <span className="span-body">&nbsp;</span>
        <span className="span-body">U</span>
        <span className="span-body">n</span>
        <span className="span-body">i</span>
        <span className="span-body">v</span>
        <span className="span-body">e</span>
        <span className="span-body">r</span>
        <span className="span-body">s</span>
        <span className="span-body">e</span>
        <span className="span-body">s</span>

        </div>
        <Link className="button" to="/work"> <span>Discover my work</span><svg width="13px" height="10px" viewBox="0 0 13 10">
         <path d="M1,5 L11,5"></path>
        <polyline points="8 1 12 5 8 9"></polyline>
        </svg> </Link>
    </div>
  );
};

export default Body;
