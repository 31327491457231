import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from '../src/components/App/';
import Work from '../src/components/Work/';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Contact from '../src/components/Contact/';
import Retro from '../src/components/Retro/';
import MentionsLegales from '../src/components/Mentionlegal/';
import PrivacyPolicy from '../src/components/PrivacyPolicy/';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/work",
    element: <Work />,
  },
  {
    path: "/accueil",
    element: <Work />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/collection/Retro-Gentleman-Animals",
    element: <Retro />,
  },
  {
    path: "/Legal-Notice",
    element: <MentionsLegales />,
  },
  {
    path: "/Privacy-Policy",
    element: <PrivacyPolicy />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
