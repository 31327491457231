import './style.css';
import Navbar from '../Navbar/';
import Body from '../Body/';
import Container from '../Container/';
import Footer from '../Footer/';

function App() {
  return(
    <>
    <Navbar />
    <Body />
    <Container />
    <Footer />
    </>
  )
}

export default App;
