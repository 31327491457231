import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-footer">
        <p className="copyright">Copyright &copy; {new Date().getFullYear()} Barkder Illustrator</p>
        <div className="links">
          <Link to="/Privacy-Policy">Privacy Policy</Link>
          <Link to="/Legal-Notice">Legal Notice</Link>
          <Link to="/contact">Contact</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
