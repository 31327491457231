import Bird from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Bird Retro gentleman animals Barkder.png';
import Cat from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Cat Retro gentleman animals Barkder.png';
import Dog from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Dog Retro gentleman animals Barkder.png';
import Fish from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Fish Retro gentleman animals Barkder.png';
import Guinea from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Guinea pig Retro gentleman animals Barkder.png';
import Horse from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Horse Retro gentleman animals Barkder.png';
import Lion from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Lion Retro gentleman animals Barkder.png';
import Mouse from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Mouse Retro gentleman animals Barkder.png';
import Rabbit from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Rabbit Retro gentleman animals Barkder.png';
import Snake from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Snake Retro gentleman animals Barkder.png';
import Turtle from '/home/nathan/Desktop/Project/barkderillustrator/src/img/Turtle Retro gentleman animals Barkder.png';

const data = [
    {
      id: 1,
      src: Bird,
      alt: "Bird Retro gentleman animals Barkder",
      title: "Bird Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 2,
      src: Dog,
      alt: "Dog Retro gentleman animals Barkder",
      title: "Dog Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 3,
      src: Cat,
      alt: "Cat Retro gentleman animals Barkder",
      title: "Cat Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 4,
      src: Fish,
      alt: "Fish Retro gentleman animals Barkder",
      title: "Fish Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 5,
      src: Snake,
      alt: "Snake Retro gentleman animals Barkder",
      title: "Snake Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 6,
      src: Turtle,
      alt: "Turtle Retro gentleman animals Barkder",
      title: "Turtle Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 7,
      src: Guinea,
      alt: "Guinea Retro gentleman animals Barkder",
      title: "Guinea Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 8,
    src: Horse,
    alt: "Horse Retro gentleman animals Barkder",
    title: "Horse Retro gentleman animals",
    collection: 'Retro gentleman animals'
   },
   {
    id: 9,
    src: Rabbit,
    alt: "Rabbit Retro gentleman animals Barkder",
    title: "Rabbit Retro gentleman animals",
    collection: 'Retro gentleman animals'
    },
  {
    id: 10,
      src: Mouse,
      alt: "Mouse Retro gentleman animals Barkder",
      title: "Mouse Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    {
      id: 11,
      src: Lion,
      alt: "Lion Retro gentleman animals Barkder",
      title: "Lion Retro gentleman animals",
      collection: 'Retro gentleman animals'
    },
    
  ];

export default data;